import { ReactElement, useEffect, useState } from "react";
import DateSelector from "components/compare_page/DateSelector";
import { Box } from "@mui/material";
import { Dayjs } from "dayjs";
import CompareSection, {
  ICompareTable,
} from "components/compare_page/CompareSection";
import { IRecord } from "model/GoogleAnalyticsResponse.interfaces";
import { getAverageData } from "utils/network";
import { useTranslation } from "react-i18next";
import { defaultBoxSXProps } from "./Home";

export function getPWA(a: boolean | undefined, b: boolean | undefined) {
  if (a === undefined || b === undefined) {
    return " ";
  }

  if (a && b) {
    return "True";
  }
  return "False";
}

export function percentageDifference(
  num1: number | undefined,
  num2: number | undefined,
  reverse = false,
): string {
  if (!!num1 && !!num2) {
    const largeNum = Math.max(num1, num2);
    const smallNum = Math.min(num1, num2);
    let percent = largeNum - smallNum;
    percent = (percent / smallNum) * 100;
    const result = percent.toFixed(2);
    if (reverse) {
      if (num1 < num2) {
        return `+${result}%`;
      }
    } else if (num1 > num2) {
      return `+${result}%`;
    }
  }
  return "";
}

const comparePercentage = (
  a: number | undefined,
  b: number | undefined,
  reverse = false,
  fractionDigits = 2,
): ReactElement => {
  const score = a;
  const percentage = percentageDifference(a, b, reverse);
  if (percentage !== "") {
    return (
      <div>
        {score?.toFixed(fractionDigits)} &nbsp;&nbsp;
        <span style={{ fontSize: "10px", color: "green" }}> {percentage} </span>
      </div>
    );
  }
  return (
    <div>
      {score?.toFixed(fractionDigits)} &nbsp;&nbsp; {percentage}
    </div>
  );
};

const Compare = (): ReactElement => {
  const { t } = useTranslation();
  const [firstDate, setFirstDate] = useState<Dayjs | null>(null);
  const [secondDate, setSecondDate] = useState<Dayjs | null>(null);
  const [firstURL, setFirstURL] = useState<string | null>(null);
  const [secondURL, setSecondURL] = useState<string | null>(null);
  const [optionOneData, setOptionOneData] = useState<IRecord | undefined>(
    undefined,
  );
  const [optionTwoData, setOptionTwoData] = useState<IRecord | undefined>(
    undefined,
  );

  const updateOptionOneData = (responseData: IRecord) => {
    setOptionOneData(responseData);
  };

  const updateOptionTwoData = (responseData: IRecord) => {
    setOptionTwoData(responseData);
  };

  // call the backend when the values in the filters change.
  useEffect(() => {
    if (firstDate && secondDate && firstURL && secondURL) {
      getAverageData(
        firstURL,
        firstDate.valueOf(),
        secondDate.valueOf(),
        updateOptionOneData,
      );
      getAverageData(
        secondURL,
        firstDate.valueOf(),
        secondDate.valueOf(),
        updateOptionTwoData,
      );
    }
  }, [firstDate, secondDate, firstURL, secondURL]);

  const handleAutoCompleteChanges = (
    _event: React.SyntheticEvent<Element, Event>,
    newValue: string | null,
    updateFirstURL = true,
  ) => {
    if (updateFirstURL) setFirstURL(newValue);
    else setSecondURL(newValue);
  };

  const handleDateChange = (date: Dayjs | null, updateFirstDate = true) => {
    if (updateFirstDate) setFirstDate(date);
    else setSecondDate(date);
  };

  const scoreTable = (
    rec1: IRecord | undefined,
    rec2: IRecord | undefined,
  ): ICompareTable => {
    const a = rec1?.score;
    const b = rec2?.score;

    return {
      url1: firstURL,
      url2: secondURL,
      rows: [
        {
          name: `${t("commonLabels.performance")}`,
          d1: comparePercentage(a?.performance, b?.performance),
          d2: comparePercentage(b?.performance, a?.performance),
        },
        {
          name: `${t("commonLabels.accessibility")}`,
          d1: comparePercentage(a?.accessibility, b?.accessibility),
          d2: comparePercentage(b?.accessibility, a?.accessibility),
        },
        {
          name: `${t("commonLabels.bestPractices")}`,
          d1: comparePercentage(a?.bestPractices, b?.bestPractices),
          d2: comparePercentage(b?.bestPractices, a?.bestPractices),
        },
        {
          name: `${t("commonLabels.sEO")}`,
          d1: comparePercentage(a?.SEO, b?.SEO),
          d2: comparePercentage(b?.SEO, a?.SEO),
        },
        {
          name: `${t("commonLabels.pWA")}`,
          d1: getPWA(a?.isPWA, b?.isPWA),
          d2: getPWA(b?.isPWA, a?.isPWA),
        },
      ],
    };
  };
  const timingTable = (
    rec1: IRecord | undefined,
    rec2: IRecord | undefined,
  ): ICompareTable => {
    const a = rec1?.timing;
    const b = rec2?.timing;

    return {
      url1: firstURL,
      url2: secondURL,
      rows: [
        {
          name: `${t("commonLabels.fCP")}`,
          d1: comparePercentage(
            a?.firstContentfulPaint,
            b?.firstContentfulPaint,
            true,
          ),
          d2: comparePercentage(
            b?.firstContentfulPaint,
            a?.firstContentfulPaint,
            true,
          ),
        },
        {
          name: `${t("commonLabels.lCP")}`,
          d1: comparePercentage(
            a?.largestContentfulPaint,
            b?.largestContentfulPaint,
            true,
          ),
          d2: comparePercentage(
            b?.largestContentfulPaint,
            a?.largestContentfulPaint,
            true,
          ),
        },
        {
          name: `${t("commonLabels.tBT")}`,
          d1: comparePercentage(
            a?.totalBlockingTime,
            b?.totalBlockingTime,
            true,
          ),
          d2: comparePercentage(
            b?.totalBlockingTime,
            a?.totalBlockingTime,
            true,
          ),
        },
        {
          name: `${t("commonLabels.sI")}`,
          d1: comparePercentage(a?.speedIndex, b?.speedIndex, true),
          d2: comparePercentage(b?.speedIndex, a?.speedIndex, true),
        },
        {
          name: `${t("commonLabels.fID")}`,
          d1: comparePercentage(
            a?.maxFirstInputDelay,
            b?.maxFirstInputDelay,
            true,
          ),
          d2: comparePercentage(
            b?.maxFirstInputDelay,
            a?.maxFirstInputDelay,
            true,
          ),
        },
        {
          name: `${t("commonLabels.cLS")}`,
          d1: comparePercentage(
            a?.cumulativeLayoutShift,
            b?.cumulativeLayoutShift,
            true,
            4,
          ),
          d2: comparePercentage(
            b?.cumulativeLayoutShift,
            a?.cumulativeLayoutShift,
            true,
            4,
          ),
        },
      ],
    };
  };

  return (
    <Box>
      <DateSelector
        handleAutoCompleteChanges={handleAutoCompleteChanges}
        firstDate={firstDate}
        secondDate={secondDate}
        handleDateChange={handleDateChange}
      />
      <Box sx={defaultBoxSXProps}>
        <Box
          display="flex"
          justifyContent="center"
          flexDirection="column"
          height="450px"
          width="95%"
          marginX="auto"
        >
          <header className="header-title">
            <h2>{t("pages.compare.score")}</h2>
          </header>
          <CompareSection
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...scoreTable(optionOneData, optionTwoData)}
          />
          <Box paddingTop="10px" display="flex" justifyContent="flex-end">
            {t("pages.compare.higherBetter")}
          </Box>
        </Box>
      </Box>
      <Box sx={defaultBoxSXProps}>
        <Box
          display="flex"
          justifyContent="center"
          flexDirection="column"
          height="500px"
          width="95%"
          marginX="auto"
        >
          <header className="header-title">
            <h2>{t("pages.compare.timing")}</h2>
          </header>
          <CompareSection
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...timingTable(optionOneData, optionTwoData)}
          />
          <Box paddingTop="10px" display="flex" justifyContent="flex-end">
            {t("pages.compare.lowerBetter")}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Compare;
