export const GET_URL_ENDPOINT = "https://geturlsv2-it3giqltxa-uc.a.run.app";

export const GET_DATA_OVER_TIME_ENDPOINT =
  "https://getdataovertimev2-it3giqltxa-uc.a.run.app";

export const GET_TOP_RECORDS_ENDPOINT =
  "https://gettoprecordsv2-it3giqltxa-uc.a.run.app";

export const GET_DATA_AVERAGE_ENDPOINT =
  "https://getaveragev2-it3giqltxa-uc.a.run.app";

export const WEEK = 604_800_000;

export const DAY = 86_400_000;

export const TODAY_DATE_STRING = new Date().toISOString().split("T")[0];
