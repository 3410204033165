import { ReactElement, useEffect, useState } from "react";
import "assets/styling/home.css";
import "assets/styling/App.css";
import AverageScore from "components/average_score/AverageScore";
import { Box } from "@mui/material";
import CustomizedTables from "components/data_tables/DataTable";
import { IRecord } from "model/GoogleAnalyticsResponse.interfaces";
import { useTranslation } from "react-i18next";
import { getTopRecords } from "utils/network";

export const defaultBoxSXProps = {
  boxShadow: 3,
  borderRadius: 2,
  marginX: "20px",
  marginY: "30px",
  paddingBottom: "20px",
};

const Home = (): ReactElement => {
  const { t } = useTranslation();
  const [bestRecord, setBestRecord] = useState<IRecord[]>([]);
  const [worstRecord, setWorstRecord] = useState<IRecord[]>([]);
  const dt = new Date().toISOString().split("T")[0];
  useEffect(() => {
    getTopRecords("best", setBestRecord);
    getTopRecords("worst", setWorstRecord);
  }, []);

  const scoreBlock: JSX.Element = (
    <Box sx={defaultBoxSXProps}>
      <header className="header-title">
        <h1>{t("pages.home.scoreCardTitle")}</h1>
      </header>
      <AverageScore />
    </Box>
  );

  const bestFiveBlock: JSX.Element = (
    <Box sx={defaultBoxSXProps}>
      <header className="header-title">
        <h1>{t("pages.home.bestTitle")}</h1>
      </header>
      <CustomizedTables records={bestRecord} />
    </Box>
  );

  const worstFiveBlock: JSX.Element = (
    <Box sx={defaultBoxSXProps}>
      <header className="header-title">
        <h1>{t("pages.home.worstTitle")}</h1>
      </header>
      <CustomizedTables records={worstRecord} />
    </Box>
  );

  return (
    <div className="App">
      <div className="home-page">
        <p className="time">
          {t("pages.home.time")} {dt} UTC
        </p>
        {scoreBlock}
        {bestFiveBlock}
        {worstFiveBlock}
      </div>
    </div>
  );
};

export default Home;
