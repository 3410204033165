import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { DateTime } from "luxon";

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: true,
    fallbackLng: "en",
    supportedLngs: ["en", "zh-TW"],
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources: {
      en: {
        translation: {
          alert: {
            rateLimited: "This Service Is Rate limited",
            message: "Please try your query again in 5 seconds",
            ok: "Ok",
          },
          nav: {
            logoName: "Continuous Insights",
            home: "Home",
            dataOverTime: "Data Over Time",
            compare: "Compare",
            about: "About",
          },
          commonLabels: {
            performance: "Performance",
            accessibility: "Accessibility",
            bestPractices: "Best Practices",
            sEO: "SEO",
            pWA: "PWA",
            startDate: "Start Date",
            endDate: "End Date",
            firstWebsite: "First Website",
            secondWebsite: "Second Websites",
            overall: "Overall", // (Overall option in Url dropdown)
            uRL: "URL",
            uRLs: "URLs",
            fCP: "First Contentful Paint",
            lCP: "Largest Contentful Paint",
            tBT: "Total Blocking Time",
            sI: "Speed Index",
            fID: "Max First Input Delay",
            cLS: "Cumulative Layout Shift",
          },
          pages: {
            home: {
              scoreCardTitle: "Score Dashboard",
              bestTitle: "Best Performing Websites",
              worstTitle: "Worst Performing Websites",
              time: "Displaying Results from",
            },
            dataOverTime: {
              scoreChart: "Score Chart",
              timingChart: "Timing Chart",
              options: "Options",
            },
            compare: {
              score: "Score",
              timing: "Timing",
              higherBetter: "The Higher the number the better",
              lowerBetter: "The Lower the number the better",
            },
            about: {
              missionStatementHeader: "Mission Statement",
              missionStatementText:
                "Continuous Insights provides an analysis of web vitals over sets of web pages. The goal is to provide highlights of organizational health and allow detailed assessment of an organization`s web pages. Continuous Insights will further allow users to explore each route in more detail within the “compare page” and “data over time page”. Using this application can help identify web pages within an organization that are worth improving. In this demo, the web pages being analyzed are the publicly available domains (home pages) of the Taiwanese government websites. With more than 300 webpages analyzed the application remains performant and efficient. Which pages are worth improving for their userbase? That is for the Taiwanese government to decide.",
              programmingTitle: "How Its Made:",
              technologiesTitle: "Basic Analytics:",
              technologiesText1:
                "Continuous Insights utilizes Google APIs to collect web analytic data. Specifically the PageSpeed Insights API is used to gather performance metrics (Performance, Accessibility, Best Practices and SEO). In addition to the scores, detailed metrics are also collected (LCP, FID, CLS, FCP, TBT) ",
              technologiesText2:
                ", which helps show which parts of a webpage are slow and why, and how users are impacted.",
              backendTitle: "Backend:",
              backendText:
                "Firebase is used as the backend, Continuous Insights uses Firebase Functions, Realtime Database, and Firestore. When implementing functions we added rate limiting instead of blocking our application behind a login, so everyone can view the demo anonymously. We also opted to make our backend efficient by duplicating some of our data so it is stored in a structure that is more easily accessed by date or page URL. Additionally we`ve setup rules so that there is a small layer of cache to reduce the number of calls required to display data on the UI.",
              frontendTitle: "Frontend:",
              frontendText:
                "When writing the frontend we choose React because it is a well supported modern web library, and it offers high performance live updates to content. We also were able to use several libraries like MUI and chart.js to quickly iterate on our simple UI/UX flow. One custom feature we spent some time on is a cache which uses local storage to allow 100 past queries, this makes the app significantly faster when switching between two sets of data and reduces our backend calls. Additionally, if your default language for your browser is Taiwanese-Chinese, then you will see a Chinese translated website when visiting Continuous Insights.",
              challengesTitle: "Challenges:",
              challengesText:
                "There were some very challenging things about making Continuous Insights, most issues with the backend and frontend were easily solved with Google, StackOverflow or ChatGPT, however there were problems that we could not resolve. The stickiest issues were related to web pages. There were issues where Google`s PageSpeed Insights API would return “Unable to resolve”. To work around this issue, we could have written our own web page crawler, however that was out of scope for our minimum viable product (MVP). The second issue is much like the first in that some websites were getting the “Unable to resolve” error due to a geo-location block from the webpage itself. Using a VPN or having a physical machine in Taiwan would have resolved this issue but again was out of scope. The third issue and worst was some webpages are just offline or unvisitable, there is no solution for that. Any webpages with issues that we described above were omitted from the dataset entirely. ",
              contactUs: "Contact Us:",
            },
          },
        },
      },
      "zh-TW": {
        translation: {
          alert: {
            rateLimited: "此服務有速率限制",
            message: "請在五秒後再試一次",
            ok: "好",
          },
          nav: {
            logoName: "持續洞察",
            home: "首頁",
            dataOverTime: "隨時間變化的數據",
            compare: "比較",
            about: "關於",
          },
          commonLabels: {
            performance: "表現",
            accessibility: "無障礙",
            bestPractices: "最佳實踐",
            sEO: "搜尋引擎優化",
            pWA: "漸進式網頁應用程式",
            startDate: "開始日期",
            endDate: "結束日期",
            firstWebsite: "第一個網站",
            secondWebsite: "第二個網站",
            overall: "全面的",
            uRL: "網址",
            uRLs: "網址",
            fCP: "第一次內容豐富的繪畫 (FCP)",
            lCP: "最大的內容塗料 (LCP)",
            tBT: "總阻塞時間 (TBT)",
            sI: "速度指數 (SI)",
            fID: "最大首次輸入延遲 (FID)",
            cLS: "累積佈局偏移 (CLS)",
          },
          pages: {
            home: {
              scoreCardTitle: "分數儀表板",
              bestTitle: "表現最佳的網站",
              worstTitle: "表現最差的網站",
              time: "顯示結果來自",
            },
            dataOverTime: {
              scoreChart: "分數表",
              timingChart: "時序圖",
              options: "選項",
            },
            compare: {
              score: "分數",
              timing: "速度",
              higherBetter: "數字越高為佳",
              lowerBetter: "數字越低為佳",
            },
            about: {
              missionStatementHeader: "使命宣言",
              missionStatementText:
                "Continuous Insights 提供對一組網頁的 Web 核心數據進行分析。其目標是提供組織健康的亮點,並允許對組織的網頁進行詳細評估。Continuous Insights 還允許用戶在“比較頁面”和“隨時間變化頁面”內更詳細地探索每個路線。使用此應用程序可以幫助確定組織中值得改進的網頁。在此演示中,分析的網頁是臺灣政府網站的公開可用域(主頁)。通過對300多個網頁的分析,該應用程序保持性能高效。哪些頁面值得為其用戶群進行改進？這由臺灣政府決定。",
              programmingTitle: "製作過程：",
              technologiesTitle: "基本分析：",
              technologiesText1:
                "Continuous Insights 利用 Google API 收集 Web 分析數據。具體而言,使用 PageSpeed Insights API 收集性能指標(性能、可訪問性、最佳實踐和 SEO)。除了分數之外,還收集了詳細的指標(LCP、FID、CLS、FCP、TBT) ",
              technologiesText2:
                ", 這有助於顯示網頁的哪些部分緩慢以及原因,以及用戶受到了多大的影響。",
              backendTitle: "後端：",
              backendText:
                "Firebase 用作後端,Continuous Insights 使用 Firebase Functions、Realtime Database 和 Firestore。在實施函數時,我們添加了速率限制,而不是將我們的應用程序鎖定在登錄後面,以便每個人都可以匿名查看演示。我們還選擇通過復制一些數據使我們的後端更高效,以便按日期或網頁 URL 存儲。此外,我們設置規則,以便有一個小的緩存層以減少在 UI 上顯示數據所需的調用次數。",
              frontendTitle: "前端：",
              frontendText:
                "在編寫前端時,我們選擇了 React,因為它是一個受支持的現代 Web 庫,並且提供了對內容的高性能實時更新。我們還能夠使用諸如 MUI 和 chart.js 之類的庫,以便快速迭代我們簡單的 UI/UX 流程。我們花了一些時間在一個使用本地存儲的緩存上,允許保存100個過去的查詢,這使得在兩組數據之間切換時應用程序顯著更快,並減少了我們的後端調用。此外,如果您的瀏覽器的默認語言是臺灣中文,那麼訪問 Continuous Insights 時將會看到一個中文翻譯的網站。",
              challengesTitle: "挑戰：",
              challengesText:
                "製作 Continuous Insights 有一些非常具有挑戰性的問題,與後端和前端相關的大多數問題都可以通過 Google、StackOverflow 或 ChatGPT 輕松解決,然而有些問題我們無法解決。最棘手的問題與網頁有關。有一些問題,Google 的 PageSpeed Insights API 會返回“無法解析”。為了解決這個問題,我們本可以編寫自己的網頁爬蟲,但這超出了我們最小可行產品(MVP)的範圍。第二個問題與第一個類似,因為一些網站由於來自網頁本身的地理位置阻止而收到“無法解析”的錯誤。使用 VPN 或在臺灣擁有一個物理機器可以解決此問題,但同樣超出了範圍。第三個問題,也是最糟糕的問題是一些網頁只是離線或無法訪問,對於這個問題沒有解決方案。任何存在上述問題的網頁都被完全從數據集中省略。",
              contactUs: "聯繫我們：",
            },
          },
        },
      },
    },
  });

// new usage
i18n?.services?.formatter?.add(
  "DATE_HUGE",
  (value, lng) =>
    DateTime?.fromJSDate(value)
      ?.setLocale(lng || "en")
      ?.toLocaleString(DateTime.DATE_HUGE),
);

export default i18n;
