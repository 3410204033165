import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useTranslation } from "react-i18next";
import { ReactElement } from "react";

export interface ICompareTableRow {
  name: string;
  d1: ReactElement | string;
  d2: ReactElement | string;
}

export interface ICompareTable {
  url1: string | null;
  url2: string | null;
  rows: Array<ICompareTableRow>;
}

const CompareSection = (args: ICompareTable): ReactElement => {
  const { t } = useTranslation();
  const { url1, url2, rows } = args;
  return (
    <TableContainer
      component={Paper}
      sx={{ display: "flex", outlineColor: "lightgray" }}
    >
      <Table aria-label="simple table">
        <TableHead sx={{ backgroundColor: "lightgray" }}>
          <TableRow>
            <TableCell sx={{ fontWeight: "Bold", width: "25%" }}>
              {t("commonLabels.uRL")}
            </TableCell>
            <TableCell sx={{ fontWeight: "Bold", width: "25%" }}>
              {url1}
            </TableCell>
            <TableCell sx={{ fontWeight: "Bold", width: "25%" }}>
              {url2}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row: ICompareTableRow) => (
            <TableRow key={row.name}>
              <TableCell sx={{ fontWeight: "Bold" }}>{row?.name}</TableCell>
              <TableCell>{row?.d1}</TableCell>
              <TableCell>{row?.d2}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default CompareSection;
