import { Box, Autocomplete, TextField } from "@mui/material";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs, { Dayjs } from "dayjs";
import utc from "dayjs/plugin/utc";
import { IRecord } from "model/GoogleAnalyticsResponse.interfaces";
import UrlContext from "pages/GlobalContext";
import { useCallback, useContext, useEffect, useState } from "react";
import { TODAY_DATE_STRING } from "utils/constants";
import { getDataOverTimeData } from "utils/network";
import { useTranslation } from "react-i18next";
import "../assets/styling/data-over-time-header.css";

dayjs.extend(utc);

interface DataOverTimeHeaderProps {
  setData: React.Dispatch<React.SetStateAction<IRecord[]>>;
}

interface DateStates {
  startDate: Dayjs;
  endDate: Dayjs;
}

const dateInitialState: DateStates = {
  startDate: dayjs.utc(TODAY_DATE_STRING).subtract(7, "day"),
  endDate: dayjs.utc(TODAY_DATE_STRING),
};

const DataOverTimeHeader = (props: DataOverTimeHeaderProps): JSX.Element => {
  const { t } = useTranslation();
  const urlList = useContext(UrlContext);
  const { setData } = props;
  const [currUrl, setCurrUrl] = useState<string | null>("Overall");
  const [dateState, setDateState] = useState(dateInitialState);
  const handleAutoCompleteChanges = (
    _event: React.SyntheticEvent<Element, Event>,
    newValue: string | null,
  ): void => {
    setCurrUrl(newValue);
  };
  const getDataCallback = useCallback(() => {
    if (currUrl && dateState.endDate && dateState.startDate) {
      const urlString = currUrl === "Overall" ? "all" : currUrl;
      const startDate = dateState.startDate.valueOf();
      const endDate = dateState.endDate.add(1, "day").valueOf();
      getDataOverTimeData(urlString, startDate, endDate, setData);
    }
  }, [currUrl, dateState]);

  useEffect(() => {
    getDataCallback();
  }, [currUrl, dateState]);

  return (
    <Box id="Filters" display="flex" justifyContent="center">
      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en">
        <Box id="URLInput" marginLeft="20px" width="50%">
          <Autocomplete
            disablePortal
            value={currUrl}
            options={urlList}
            sx={{ width: "100%" }}
            onChange={handleAutoCompleteChanges}
            renderInput={(params) => (
              // eslint-disable-next-line react/jsx-props-no-spreading, @typescript-eslint/no-explicit-any
              <TextField {...(params as any)} label={t("commonLabels.uRLs")} />
            )}
          />
        </Box>
        <Box
          id="dateInputs"
          display="flex"
          width="70%"
          justifyContent="flex-end"
          style={{ paddingRight: "20px" }}
        >
          <DatePicker
            label={t("commonLabels.startDate")}
            value={dateState.startDate}
            onChange={(date) => {
              setDateState({ ...dateState, startDate: dayjs.utc(date) });
            }}
            disableFuture
            timezone="UTC"
          />
          <Box
            id="paddingBox"
            display="flex"
            flexDirection="column"
            justifyContent="center"
            paddingX="20px"
          >
            -
          </Box>
          <DatePicker
            label={t("commonLabels.endDate")}
            minDate={dateState.startDate}
            value={dateState.endDate}
            onChange={(date) => {
              setDateState({ ...dateState, endDate: dayjs.utc(date) });
            }}
            disableFuture
            timezone="UTC"
          />
        </Box>
      </LocalizationProvider>
    </Box>
  );
};

export default DataOverTimeHeader;
