import { ReactElement } from "react";
import { useTranslation } from "react-i18next";

const About = (): ReactElement => {
  const { t } = useTranslation();

  return (
    <div
      style={{
        fontFamily: "Georgia,Cambria,Times New Roman,Times,serif;",
        lineHeight: "1.58",
        marginLeft: "auto",
        marginRight: "auto",
        maxWidth: "800px",
        paddingTop: "100px",
        paddingLeft: "24px",
        paddingRight: "24px",
      }}
    >
      <h2 style={{ paddingTop: "24px", marginBottom: "8px" }}>
        {t("pages.about.missionStatementHeader")}
      </h2>
      <span>{t("pages.about.missionStatementText")}</span>
      <h2 style={{ paddingTop: "24px", marginBottom: "0px" }}>
        {t("pages.about.programmingTitle")}
      </h2>
      <h3 style={{ paddingTop: "0px", marginBottom: "4px" }}>
        {t("pages.about.technologiesTitle")}
      </h3>
      <span>{t("pages.about.technologiesText1")}</span>
      <span>
        <a
          style={{ textDecoration: "underline" }}
          href="https://web.dev/explore/metrics#overview"
        >
          https://web.dev/explore/metrics#overview
        </a>
      </span>
      <span>{t("pages.about.technologiesText2")}</span>
      <h3 style={{ paddingTop: "8px", marginBottom: "4px" }}>
        {t("pages.about.backendTitle")}
      </h3>
      <span>{t("pages.about.backendText")}</span>
      <h3 style={{ paddingTop: "8px", marginBottom: "4px" }}>
        {t("pages.about.frontendTitle")}
      </h3>
      <span>{t("pages.about.frontendText")}</span>
      <h2 style={{ paddingTop: "24px", marginBottom: "8px" }}>
        {t("pages.about.challengesTitle")}
      </h2>
      <span>{t("pages.about.challengesText")}</span>
      <h2 style={{ paddingTop: "24px", marginBottom: "8px" }}>
        {t("pages.about.contactUs")}
      </h2>
      <span>
        <a
          style={{ textDecoration: "underline" }}
          href="mailto:contact@continuousinsights.app"
        >
          contact@continuousinsights.app
        </a>
      </span>
      <br />
      <br />
      <br />
      <br />
    </div>
  );
};

export default About;
