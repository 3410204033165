import { Theme, ThemeProvider } from "@emotion/react";
import {
  Button,
  Menu,
  MenuItem,
  FormControlLabel,
  Checkbox,
  createTheme,
} from "@mui/material";
import { blueGrey, grey } from "@mui/material/colors";
import { useState } from "react";
import { useTranslation } from "react-i18next";

export interface MenuItemOptionProps {
  disabled: boolean;
  onChange: (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean,
  ) => void;
  label: string;
  checked: boolean;
}

export interface GraphOptionsProps {
  graphOptions: MenuItemOptionProps[];
}

const customTheme: Theme = createTheme({
  palette: {
    primary: blueGrey,
    secondary: grey,
  },
  typography: {
    button: {
      textTransform: "none",
    },
  },
});

const GraphOptions = (props: GraphOptionsProps): JSX.Element => {
  const { t } = useTranslation();

  const { graphOptions } = props;
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <ThemeProvider theme={customTheme}>
      <Button
        variant="outlined"
        aria-haspopup="true"
        size="small"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        sx={{ color: "black" }}
      >
        {t("pages.dataOverTime.options")}
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        {graphOptions.map(({ disabled, onChange, label, checked }) => (
          <MenuItem>
            <FormControlLabel
              control={
                <Checkbox
                  size="small"
                  checked={checked}
                  disabled={disabled}
                  onChange={onChange}
                />
              }
              label={label}
            />
          </MenuItem>
        ))}
      </Menu>
    </ThemeProvider>
  );
};

export default GraphOptions;
