import { ReactElement } from "react";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "assets/styling/progress-bar.css";
import "react-circular-progressbar/dist/styles.css";

const RED_CSS = "#DF3520";
const YELLOW_CSS = "#F9CF57";
const GREEN_CSS = "#5DC264";
const BLUE_CSS = "#16AEEF";
const BLACK_CSS = "#000000";

interface ProgressBarProps {
  value: number;
  text: string;
}

interface ChartProps {
  children: ReactElement;
  label: string;
}

const getColor = (value: number): string => {
  if (value < 25) {
    return RED_CSS;
  }
  if (value <= 75) {
    return YELLOW_CSS;
  }
  if (value <= 99) {
    return GREEN_CSS;
  }
  return BLUE_CSS;
};

const Chart = (props: ChartProps): ReactElement => {
  const { label, children } = props;

  return (
    <div className="graph-container">
      <div className="graph-title">
        <h3>{label}</h3>
      </div>
      <div className="graph">{children}</div>
    </div>
  );
};

const ProgressBar = (props: ProgressBarProps): ReactElement => {
  const { value, text } = props;
  const chartColor = getColor(value);
  const progressText = `${value.toString()}%`;

  return (
    <Chart label={text}>
      <CircularProgressbar
        value={value}
        text={progressText}
        styles={buildStyles({ pathColor: chartColor, textColor: BLACK_CSS })}
      />
    </Chart>
  );
};

export default ProgressBar;
