import { ChartDataset, ChartOptions } from "chart.js";
import { IRecord } from "model/GoogleAnalyticsResponse.interfaces";

interface DataMapping {
  SEO: (number | null)[];
  bestPractices: (number | null)[];
  accessibility: (number | null)[];
  performance: (number | null)[];
  firstContentfulPaint: (number | null)[];
  largestContentfulPaint: (number | null)[];
  totalBlockingTime: (number | null)[];
  speedIndex: (number | null)[];
  maxFirstInputDelay: (number | null)[];
  cumulativeLayoutShift: (number | null)[];
}

export interface ScoreDataDisplayStates {
  displaySEO: boolean;
  displayBestPractices: boolean;
  displayAccessibility: boolean;
  displayPerformance: boolean;
}

export interface TimingDataDisplayStates {
  displayFirstContentfulPaint: boolean;
  displayLargestContentfulPaint: boolean;
  displayTotalBlockingTime: boolean;
  displaySpeedIndex: boolean;
  displayMaxFirstInputDelay: boolean;
  displayCumulativeLayoutShift: boolean;
}

export const options = (titleText: string): ChartOptions<"line"> => ({
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      position: "bottom" as const,
      labels: {
        usePointStyle: true,
      },
    },
    title: {
      display: true,
      text: titleText,
      font: {
        size: 24,
      },
      padding: {
        top: 20,
        bottom: 20,
      },
    },
    tooltip: {
      usePointStyle: true,
    },
  },
});

const pointSizingStyle: Partial<ChartDataset<"line">> = {
  pointBackgroundColor: "rgb(255,255,255)",
  pointRadius: 5,
  pointHoverRadius: 8,
  borderWidth: 1,
  pointBorderWidth: 1,
};

export const getSpecificGraphData = (
  labelName: string,
  data: (number | null)[],
  colorCode: string,
  pointStyle: string,
): ChartDataset<"line"> => ({
  label: labelName,
  data,
  borderColor: colorCode,
  pointHoverBackgroundColor: colorCode,
  pointStyle,
  ...pointSizingStyle,
});

const overallDataMapping = (data: IRecord[], total: number): DataMapping => ({
  SEO: data.map((item) => (item.score ? item.score.SEO / total : null)),
  bestPractices: data.map((item) =>
    item.score ? item.score.bestPractices / total : null,
  ),
  accessibility: data.map((item) =>
    item.score ? item.score.accessibility / total : null,
  ),
  performance: data.map((item) =>
    item.score ? item.score.performance / total : null,
  ),
  firstContentfulPaint: data.map((item) =>
    item.timing ? item.timing.firstContentfulPaint / total : null,
  ),
  largestContentfulPaint: data.map((item) =>
    item.timing ? item.timing.largestContentfulPaint / total : null,
  ),
  totalBlockingTime: data.map((item) =>
    item.timing ? item.timing.totalBlockingTime / total : null,
  ),
  speedIndex: data.map((item) =>
    item.timing ? item.timing.speedIndex / total : null,
  ),
  maxFirstInputDelay: data.map((item) =>
    item.timing ? item.timing.maxFirstInputDelay / total : null,
  ),
  cumulativeLayoutShift: data.map((item) =>
    item.timing ? item.timing.cumulativeLayoutShift / total : null,
  ),
});

const dataMapping = (data: IRecord[]): DataMapping => ({
  SEO: data.map((item) => (item.score ? item.score.SEO : null)),
  bestPractices: data.map((item) =>
    item.score ? item.score.bestPractices : null,
  ),
  accessibility: data.map((item) =>
    item.score ? item.score.accessibility : null,
  ),
  performance: data.map((item) => (item.score ? item.score.performance : null)),
  firstContentfulPaint: data.map((item) =>
    item.timing ? item.timing.firstContentfulPaint : null,
  ),
  largestContentfulPaint: data.map((item) =>
    item.timing ? item.timing.largestContentfulPaint : null,
  ),
  totalBlockingTime: data.map((item) =>
    item.timing ? item.timing.totalBlockingTime : null,
  ),
  speedIndex: data.map((item) => (item.timing ? item.timing.speedIndex : null)),
  maxFirstInputDelay: data.map((item) =>
    item.timing ? item.timing.maxFirstInputDelay : null,
  ),
  cumulativeLayoutShift: data.map((item) =>
    item.timing ? item.timing.cumulativeLayoutShift : null,
  ),
});

export const populateScoreDataSets = (
  data: IRecord[],
  scoreDisplayState: ScoreDataDisplayStates,
  total?: number,
): ChartDataset<"line">[] => {
  const datasetList: ChartDataset<"line">[] = [];
  const scoreDataMapping: DataMapping = total
    ? overallDataMapping(data, total)
    : dataMapping(data);
  if (scoreDisplayState.displayAccessibility)
    datasetList.push(
      getSpecificGraphData(
        "Accessibility",
        scoreDataMapping.accessibility,
        "rgb(66, 245, 218)",
        "rect",
      ),
    );
  if (scoreDisplayState.displayBestPractices)
    datasetList.push(
      getSpecificGraphData(
        "Best Practices",
        scoreDataMapping.bestPractices,
        "rgb(53, 162, 235)",
        "triangle",
      ),
    );
  if (scoreDisplayState.displayPerformance)
    datasetList.push(
      getSpecificGraphData(
        "Performance",
        scoreDataMapping.performance,
        "rgb(142, 47, 224)",
        "rectRot",
      ),
    );
  if (scoreDisplayState.displaySEO)
    datasetList.push(
      getSpecificGraphData(
        "SEO",
        scoreDataMapping.SEO,
        "rgb(255, 99, 132)",
        "circle",
      ),
    );
  return datasetList;
};

export const populateTimingDataSets = (
  data: IRecord[],
  timingDisplayState: TimingDataDisplayStates,
  total?: number,
): ChartDataset<"line">[] => {
  const datasetList: ChartDataset<"line">[] = [];
  const timingDataMapping: DataMapping = total
    ? overallDataMapping(data, total)
    : dataMapping(data);
  if (timingDisplayState.displayFirstContentfulPaint)
    datasetList.push(
      getSpecificGraphData(
        "First Contentful Paint",
        timingDataMapping.firstContentfulPaint,
        "rgb(66, 245, 218)",
        "rect",
      ),
    );
  if (timingDisplayState.displayLargestContentfulPaint)
    datasetList.push(
      getSpecificGraphData(
        "Largest Contentful Paint",
        timingDataMapping.largestContentfulPaint,
        "rgb(53, 162, 235)",
        "triangle",
      ),
    );
  if (timingDisplayState.displayTotalBlockingTime)
    datasetList.push(
      getSpecificGraphData(
        "Total Blocking Time",
        timingDataMapping.totalBlockingTime,
        "rgb(142, 47, 224)",
        "rectRot",
      ),
    );
  if (timingDisplayState.displaySpeedIndex)
    datasetList.push(
      getSpecificGraphData(
        "Speed Index",
        timingDataMapping.speedIndex,
        "rgb(255, 99, 132)",
        "circle",
      ),
    );
  if (timingDisplayState.displayMaxFirstInputDelay)
    datasetList.push(
      getSpecificGraphData(
        "Max First Input Delay",
        timingDataMapping.maxFirstInputDelay,
        "rgb(245, 206, 66)",
        "star",
      ),
    );
  if (timingDisplayState.displayCumulativeLayoutShift)
    datasetList.push(
      getSpecificGraphData(
        "Cumulative Layout Shift",
        timingDataMapping.cumulativeLayoutShift,
        "rgb(45, 227, 57)",
        "crossRot",
      ),
    );
  return datasetList;
};
