import { Routes, Route, BrowserRouter as Router } from "react-router-dom";
import Navbar from "components/navbar/Navbar";
import DataOvertime from "pages/DataOverTime";
import About from "pages/About";
import Compare from "pages/Compare";
import Home from "pages/Home";
import { useCallback, useEffect, useState } from "react";
import { CircularProgress } from "@mui/material";
import { Box } from "@mui/system";
import UrlContext from "pages/GlobalContext";
import { GET_URL_ENDPOINT } from "utils/constants";
import { cachedFetch } from "utils/network";
import { useTranslation } from "react-i18next";
import Alert from "components/alert/AlertComponent";

const App = (): JSX.Element => {
  const { t } = useTranslation();
  const [urlList, setUrlList] = useState([]);
  const [loading, setLoading] = useState(true);
  const addOverall = (data: string[]) => {
    data.unshift(t("commonLabels.overall"));
  };
  const getUrlList = useCallback(() => {
    cachedFetch(GET_URL_ENDPOINT, setUrlList, setLoading, addOverall);
  }, [urlList]);

  useEffect(() => {
    getUrlList();
  }, []);

  return (
    <UrlContext.Provider value={urlList}>
      {loading ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="100px"
        >
          <CircularProgress />
        </Box>
      ) : (
        <Router>
          <Navbar />
          <Alert />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/Compare" element={<Compare />} />
            <Route path="/DataOvertime" element={<DataOvertime />} />
            <Route path="/About" element={<About />} />
          </Routes>
        </Router>
      )}
    </UrlContext.Provider>
  );
};

export default App;
