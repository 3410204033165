import { ReactElement, useEffect, useState } from "react";
import "assets/styling/average-score.css";
import dayjs from "dayjs";
import { TODAY_DATE_STRING } from "utils/constants";
import { getDataOverTimeData } from "utils/network";
import { IRecord } from "model/GoogleAnalyticsResponse.interfaces";
import { useTranslation } from "react-i18next";
import ProgressBar from "./ProgressBar";

interface AverageScoreStates {
  performance: number;
  accessibility: number;
  bestPractices: number;
  SEO: number;
}

const AverageScore = (): ReactElement => {
  const { t } = useTranslation();
  const [averageScores, setAverageScores] = useState<AverageScoreStates>({
    performance: 0,
    accessibility: 0,
    bestPractices: 0,
    SEO: 0,
  });

  const updateScore = (data: IRecord[]) => {
    const todayData = data.pop();
    const todayScore = todayData?.score;

    if (todayScore) {
      const dinominator = todayData.total || 1;
      setAverageScores({
        performance: (todayScore.performance / dinominator) * 100,
        accessibility: (todayScore.accessibility / dinominator) * 100,
        bestPractices: (todayScore.bestPractices / dinominator) * 100,
        SEO: (todayScore.SEO / dinominator) * 100,
      });
    }
  };

  useEffect(() => {
    const startDate = dayjs.utc(TODAY_DATE_STRING).subtract(1, "day").valueOf();
    const endDate = dayjs.utc(TODAY_DATE_STRING).add(1, "day").valueOf();
    getDataOverTimeData("all", startDate, endDate, updateScore);
  }, []);

  return (
    <div id="DivForData">
      <ProgressBar
        value={Math.round(averageScores.performance)}
        text={t("commonLabels.performance")}
      />
      <ProgressBar
        value={Math.round(averageScores.accessibility)}
        text={t("commonLabels.accessibility")}
      />
      <ProgressBar
        value={Math.round(averageScores.bestPractices)}
        text={t("commonLabels.bestPractices")}
      />
      <ProgressBar
        value={Math.round(averageScores.SEO)}
        text={t("commonLabels.sEO")}
      />
    </div>
  );
};

export default AverageScore;
