import { IRecord } from "model/GoogleAnalyticsResponse.interfaces";
import axios from "axios";
import {
  GET_URL_ENDPOINT,
  WEEK,
  DAY,
  GET_DATA_AVERAGE_ENDPOINT,
  GET_DATA_OVER_TIME_ENDPOINT,
  GET_TOP_RECORDS_ENDPOINT,
} from "./constants";

interface DataOverTimeRequest {
  url: string;
  startDate: number;
  endDate: number;
}

interface GetTopRecordsRequest {
  date: number;
  amount: number;
  best: string;
}

type SetDataFuntionType =
  | React.Dispatch<React.SetStateAction<IRecord[]>>
  | React.Dispatch<React.SetStateAction<never[]>>
  | ((data: IRecord) => void)
  | ((data: IRecord[]) => void);

export const cachedFetch = (
  apiUrl: string,
  setData: SetDataFuntionType,
  setLoading?: (value: React.SetStateAction<boolean>) => void,
  dataMassaging?: (data: string[]) => void,
) => {
  const cacheData = localStorage.getItem(apiUrl);

  if (cacheData) {
    setData(JSON.parse(cacheData).value);
    setLoading?.(false);
    return;
  }

  axios
    .get(apiUrl)
    .then((responseData) => {
      dataMassaging?.(responseData?.data);
      const item = {
        value: responseData?.data,
        expiry: new Date().getTime() + apiUrl === GET_URL_ENDPOINT ? WEEK : DAY,
      };
      localStorage.setItem(apiUrl, JSON.stringify(item));
      setData(responseData?.data);
      setLoading?.(false);
    })
    .catch((error) => {
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        // console.log(error.response.status);
        // 'Something went wrong, if you want to report a bug please message us at bugs@funlanguage.com'
      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        document.getElementById("avoidGlobalStateSetupHack")?.click();
        // eslint-disable-next-line no-console
        // console.log("500", error.request);
        // 'something went wrong, We have a rate limiter so try again in 5 seconds'
      } else {
        // Something happened in setting up the request that triggered an Error
        // 'Sorry that seems to have been a bad request'
      }
      // eslint-disable-next-line no-console
      // console.log(error.config);
      // TODO: decide on what type of error handling needed.
      // need to catch network error and local storage error.
      setLoading?.(false);
    });
};

export const getDataOverTimeData = (
  url: string,
  startDate: number,
  endDate: number,
  setData: SetDataFuntionType,
  setLoading?: (value: React.SetStateAction<boolean>) => void,
) => {
  const params: DataOverTimeRequest = {
    url,
    startDate,
    endDate,
  };
  const queryString = Object.keys(params)
    .map(
      (key) =>
        `${encodeURIComponent(key)}=${encodeURIComponent(
          params[key as keyof DataOverTimeRequest],
        )}`,
    )
    .join("&");

  const apiUrl = `${GET_DATA_OVER_TIME_ENDPOINT}?${queryString}`;

  cachedFetch(apiUrl, setData, setLoading);
};

export const getAverageData = (
  url: string,
  startDate: number,
  endDate: number,
  setData: (responseData: IRecord) => void,
  setLoading?: (value: React.SetStateAction<boolean>) => void,
) => {
  const params: DataOverTimeRequest = {
    url: url === "Overall" ? "all" : url,
    startDate,
    endDate,
  };
  const queryString = Object.keys(params)
    .map(
      (key) =>
        `${encodeURIComponent(key)}=${encodeURIComponent(
          params[key as keyof DataOverTimeRequest],
        )}`,
    )
    .join("&");

  const apiUrl = `${GET_DATA_AVERAGE_ENDPOINT}?${queryString}`;

  cachedFetch(apiUrl, setData, setLoading);
};

export const getTopRecords = (
  best: string,
  setData: React.Dispatch<React.SetStateAction<IRecord[]>>,
) => {
  const dateString = new Date().toISOString().split("T")[0];
  const params: GetTopRecordsRequest = {
    date: new Date(dateString).getTime() - DAY,
    amount: 20,
    best,
  };
  const queryString = Object.keys(params)
    .map(
      (key) =>
        `${encodeURIComponent(key)}=${encodeURIComponent(
          params[key as keyof GetTopRecordsRequest],
        )}`,
    )
    .join("&");
  const apiUrl = `${GET_TOP_RECORDS_ENDPOINT}?${queryString}`;

  cachedFetch(apiUrl, setData);
};
