import { ReactElement } from "react";
import "../../assets/styling/DataTable.css";
import { IRecord } from "model/GoogleAnalyticsResponse.interfaces";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";

interface CustomizeTablesProps {
  records: IRecord[];
}

const defaultprops: CustomizeTablesProps = {
  records: [],
};

const getColumns = (): GridColDef[] => {
  const { t } = useTranslation();

  return [
    { field: "url", headerName: `${t("commonLabels.uRL")}`, width: 250 },
    {
      field: "performance",
      headerName: `${t("commonLabels.performance")}`,
      width: 200,
    },
    {
      field: "firstContentfulPaint",
      headerName: `${t("commonLabels.fCP")}`,
      width: 200,
    },
    {
      field: "largestContentfulPaint",
      headerName: `${t("commonLabels.lCP")}`,
      width: 200,
    },
    {
      field: "totalBlockingTime",
      headerName: `${t("commonLabels.tBT")}`,
      width: 200,
    },
    { field: "speedIndex", headerName: `${t("commonLabels.sI")}`, width: 200 },
    {
      field: "maxFirstInputDelay",
      headerName: `${t("commonLabels.fID")}`,
      width: 200,
    },
    {
      field: "cumulativeLayoutShift",
      headerName: `${t("commonLabels.cLS")}`,
      width: 200,
    },
  ];
};

const getRows = (records: IRecord[]) =>
  records.map((item: IRecord, index: number) => ({
    id: index,
    url: item.url,
    performance: item.score?.performance,
    firstContentfulPaint: item.timing?.firstContentfulPaint,
    largestContentfulPaint: item.timing?.largestContentfulPaint,
    totalBlockingTime: item.timing?.totalBlockingTime,
    speedIndex: item.timing?.speedIndex,
    maxFirstInputDelay: item.timing?.maxFirstInputDelay,
    cumulativeLayoutShift: item.timing?.cumulativeLayoutShift,
  }));

const CustomizeTables = (
  props: CustomizeTablesProps = defaultprops,
): ReactElement => {
  const { records } = props;

  return (
    <Box width="90%" margin="auto">
      <DataGrid
        rows={getRows(records)}
        columns={getColumns()}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 5 },
          },
        }}
        pageSizeOptions={[5, 10, 15, 20]}
      />
    </Box>
  );
};

export default CustomizeTables;
