import { Box, createTheme, ThemeProvider } from "@mui/material";
import DataOverTimeHeader from "components/DataOverTimeHeader";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip as ChartTooltip,
  Legend as ChartLegend,
  ChartData,
} from "chart.js";
import { Line as LineChartJs } from "react-chartjs-2";
import { useReducer, useState } from "react";
import GraphOptions, { MenuItemOptionProps } from "components/GraphOptions";
import {
  options,
  populateScoreDataSets,
  populateTimingDataSets,
  ScoreDataDisplayStates,
  TimingDataDisplayStates,
} from "utils/chart/chart-util";
import { IRecord } from "model/GoogleAnalyticsResponse.interfaces";
import { useTranslation } from "react-i18next";
import { defaultBoxSXProps } from "./Home";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  ChartTooltip,
  ChartLegend,
);

const scoreDataDisplayInitialStates: ScoreDataDisplayStates = {
  displaySEO: true,
  displayBestPractices: true,
  displayAccessibility: true,
  displayPerformance: true,
};

const timingDataDisplayInitialStates: TimingDataDisplayStates = {
  displayFirstContentfulPaint: true,
  displayLargestContentfulPaint: true,
  displayTotalBlockingTime: true,
  displaySpeedIndex: true,
  displayMaxFirstInputDelay: true,
  displayCumulativeLayoutShift: true,
};

type ReducerAction =
  | { type: "incremented_count" }
  | { type: "decremented_count" };

const reducer = (state: number, action: ReducerAction): number => {
  if (action.type === "incremented_count") {
    return state + 1;
  }
  return state - 1;
};

const displayStateMapping = (value: boolean, label: string) => {
  const { t } = useTranslation();

  switch (label) {
    case t("commonLabels.accessibility"): {
      return { displayAccessibility: value };
    }
    case t("commonLabels.bestPractices"): {
      return { displayBestPractices: value };
    }
    case t("commonLabels.performance"): {
      return { displayPerformance: value };
    }
    case t("commonLabels.sEO"): {
      return { displaySEO: value };
    }
    case t("commonLabels.fCP"): {
      return { displayFirstContentfulPaint: value };
    }
    case t("commonLabels.lCP"): {
      return { displayLargestContentfulPaint: value };
    }
    case t("commonLabels.tBT"): {
      return { displayTotalBlockingTime: value };
    }
    case t("commonLabels.sI"): {
      return { displaySpeedIndex: value };
    }
    case t("commonLabels.fID"): {
      return { displayMaxFirstInputDelay: value };
    }
    case t("commonLabels.cLS"): {
      return { displayCumulativeLayoutShift: value };
    }
    default: {
      return undefined;
    }
  }
};

const generateScoreGraphOptions = (
  scoreDisplayState: ScoreDataDisplayStates,
  generategMenuItemOptionProps: (
    currDisplayState: boolean,
    label: string,
    score?: boolean,
  ) => MenuItemOptionProps,
) => {
  const { t } = useTranslation();

  return [
    generategMenuItemOptionProps(
      scoreDisplayState.displayAccessibility,
      t("commonLabels.accessibility"),
    ),
    generategMenuItemOptionProps(
      scoreDisplayState.displayBestPractices,
      t("commonLabels.bestPractices"),
    ),
    generategMenuItemOptionProps(
      scoreDisplayState.displayPerformance,
      t("commonLabels.performance"),
    ),
    generategMenuItemOptionProps(
      scoreDisplayState.displaySEO,
      t("commonLabels.sEO"),
    ),
  ];
};

const generateTimingGraphOptions = (
  timingDisplayState: TimingDataDisplayStates,
  generategMenuItemOptionProps: (
    currDisplayState: boolean,
    label: string,
    score?: boolean,
  ) => MenuItemOptionProps,
) => {
  const { t } = useTranslation();

  return [
    generategMenuItemOptionProps(
      timingDisplayState.displayCumulativeLayoutShift,
      t("commonLabels.cLS"),
      false,
    ),
    generategMenuItemOptionProps(
      timingDisplayState.displayFirstContentfulPaint,
      t("commonLabels.fCP"),
      false,
    ),
    generategMenuItemOptionProps(
      timingDisplayState.displayLargestContentfulPaint,
      t("commonLabels.lCP"),
      false,
    ),
    generategMenuItemOptionProps(
      timingDisplayState.displayMaxFirstInputDelay,
      t("commonLabels.fID"),
      false,
    ),
    generategMenuItemOptionProps(
      timingDisplayState.displaySpeedIndex,
      t("commonLabels.sI"),
      false,
    ),
    generategMenuItemOptionProps(
      timingDisplayState.displayTotalBlockingTime,
      t("commonLabels.tBT"),
      false,
    ),
  ];
};

const theme = createTheme({
  typography: {
    button: {
      textTransform: "none",
    },
  },
});

const DataOvertime = (): JSX.Element => {
  const [scoreDisplayState, setScoreDisplayState] = useState(
    scoreDataDisplayInitialStates,
  );
  const [timingDisplayState, setTimingDisplayState] = useState(
    timingDataDisplayInitialStates,
  );
  const [numberOfDisplayedScores, dispatchNumberOfDisplayedScores] = useReducer(
    reducer,
    4,
  );
  const [numberOfDisplayedTimings, dispatchNumberOfDisplayedTimings] =
    useReducer(reducer, 6);
  const [data, setData] = useState<IRecord[] | []>([]);

  const scoreDataSets = data?.length
    ? populateScoreDataSets(data, scoreDisplayState, data[0].total)
    : [];
  const timingDataSets = data?.length
    ? populateTimingDataSets(data, timingDisplayState, data[0].total)
    : [];
  const scoreChartData: ChartData<"line"> = {
    labels:
      data && data?.length > 0
        ? data.map(
          (item) => new Date(item.date as string).toISOString().split("T")[0],
        )
        : [],
    datasets: scoreDataSets,
  };
  const timingChartData: ChartData<"line"> = {
    labels:
      data && data?.length > 0
        ? data?.map(
          (item) => new Date(item.date as string).toISOString().split("T")[0],
        )
        : [],
    datasets: timingDataSets,
  };

  const generategMenuItemOptionProps = (
    currDisplayState: boolean,
    label: string,
    score = true,
  ): MenuItemOptionProps => {
    const updatedDisplayState = displayStateMapping(!currDisplayState, label);
    return {
      disabled: score
        ? numberOfDisplayedScores < 2 && currDisplayState
        : numberOfDisplayedTimings < 2 && currDisplayState,
      onChange: () => {
        if (score) {
          dispatchNumberOfDisplayedScores({
            type: !currDisplayState ? "incremented_count" : "decremented_count",
          });
          setScoreDisplayState({
            ...scoreDisplayState,
            ...updatedDisplayState,
          });
        } else {
          dispatchNumberOfDisplayedTimings({
            type: !currDisplayState ? "incremented_count" : "decremented_count",
          });
          setTimingDisplayState({
            ...timingDisplayState,
            ...updatedDisplayState,
          });
        }
      },
      label,
      checked: currDisplayState,
    };
  };

  const scoreGraphOptions = generateScoreGraphOptions(
    scoreDisplayState,
    generategMenuItemOptionProps,
  );
  const timingGraphOptions = generateTimingGraphOptions(
    timingDisplayState,
    generategMenuItemOptionProps,
  );

  const { t } = useTranslation();

  return (
    <ThemeProvider theme={theme}>
      <Box paddingTop="100px">
        <DataOverTimeHeader setData={setData} />

        <Box sx={defaultBoxSXProps}>
          <Box
            display="flex"
            justifyContent="center"
            height="350px"
            width="95%"
            marginX="auto"
          >
            <LineChartJs
              options={options(t("pages.dataOverTime.scoreChart"))}
              data={scoreChartData}
            />
          </Box>

          <Box display="flex" flexDirection="row-reverse" marginRight="30px">
            <GraphOptions graphOptions={scoreGraphOptions} />
          </Box>
        </Box>

        <Box sx={defaultBoxSXProps}>
          <Box
            display="flex"
            justifyContent="center"
            height="350px"
            width="95%"
            marginX="auto"
          >
            <LineChartJs
              options={options(t("pages.dataOverTime.timingChart"))}
              data={timingChartData}
            />
          </Box>

          <Box display="flex" flexDirection="row-reverse" marginRight="30px">
            <GraphOptions graphOptions={timingGraphOptions} />
          </Box>
        </Box>
      </Box>
    </ThemeProvider>
  );
};

export default DataOvertime;
