import { ReactElement, useEffect } from "react";
import "assets/styling/navbar.css";
import { AppBar, Toolbar, Box } from "@mui/material";
import Button from "@mui/material/Button";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

interface ENavItem {
  path: string;
  element: ReactElement;
}

const titleButtonStyling = {
  display: {
    // xs: "block",
    sm: "block",
  },
  "&:hover": {
    backgroundColor: "transparent",
  },
  margin: "auto",
  padding: "auto",
  justifyContent: "center",
  alignContent: "center",
  width: { xs: "50px", sm: "75px" },
  textTransform: "none",
};

const boxStyling = {
  display: { xs: "block", sm: "block" },
};

const windowWidth = window.innerWidth;

let linkSize;
if (windowWidth < 376) {
  linkSize = "xx-small";
} else if (windowWidth < 431) {
  linkSize = "x-small";
} else if (windowWidth > 767) {
  linkSize = "medium";
} else {
  linkSize = "large";
}
const navPageButtonStyling = {
  fontSize: linkSize,
  padding: "4px",
};

const Navbar = (): ReactElement => {
  const { t, i18n } = useTranslation();

  useEffect(() => {
    const lang = navigator.language;
    i18n.changeLanguage(lang);
  }, []);

  const isCurrent = (path: string): "" | "current" => {
    let title = "Continuous Insights";
    switch (document.location.pathname) {
      case "/DataOvertime":
        title += ` - ${t("nav.dataOverTime")}`;
        break;
      case "/Compare":
        title += ` - ${t("nav.compare")}`;
        break;
      case "/About":
        title += ` - ${t("nav.about")}`;
        break;
      default:
        break;
    }
    document.title = title;

    if (document.location.pathname === path) {
      return "current";
    }
    return "";
  };

  const navItems: Array<ENavItem> = [
    {
      path: "/",
      element: <Link to="/">{t("nav.home")}</Link>,
    },
    {
      path: "/DataOvertime",
      element: <Link to="/DataOvertime">{t("nav.dataOverTime")}</Link>,
    },
    {
      path: "/Compare",
      element: <Link to="/Compare">{t("nav.compare")}</Link>,
    },
    {
      path: "/About",
      element: <Link to="/About">{t("nav.about")}</Link>,
    },
  ];
  const navigate = useNavigate();

  return (
    <AppBar
      component="nav"
      color="transparent"
      sx={{ backgroundColor: "lightgray" }}
    >
      <Toolbar>
        <Button
          color="inherit"
          variant="text"
          size="large"
          sx={titleButtonStyling}
          onClick={() => {
            navigate("/");
          }}
        >
          <img
            src="logo128.png"
            id="logoStyle"
            alt="Continuous Insights Logo"
          />
        </Button>
        <Box sx={{ flexGrow: 1, ...boxStyling }} />
        <Box sx={boxStyling}>
          {navItems.map((item: ENavItem) => (
            <Button
              onClick={() => {
                navigate(item.path);
              }}
              color="inherit"
              className={`${isCurrent(item.path)}`}
              sx={navPageButtonStyling}
            >
              {item.element}
            </Button>
          ))}
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default Navbar;
