import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Autocomplete, Box, TextField } from "@mui/material";
import { Dayjs } from "dayjs";
import UrlContext from "pages/GlobalContext";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import "../../assets/styling/date-selector.css";

export interface DateSelectorProps {
  handleAutoCompleteChanges: (
    _event: React.SyntheticEvent<Element, Event>,
    newValue: string | null,
    updateFirstURL?: boolean,
  ) => void;
  firstDate: Dayjs | null;
  secondDate: Dayjs | null;
  handleDateChange: (date: Dayjs | null, updateFirstDate?: boolean) => void;
}

const DateSelector = (props: DateSelectorProps) => {
  const { handleAutoCompleteChanges, firstDate, secondDate, handleDateChange } =
    props;
  const urlList = useContext(UrlContext);
  const { t } = useTranslation();

  return (
    <Box
      id="CompareFilters"
      display="flex"
      justifyContent="space-between"
      paddingTop="100px"
    >
      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en">
        <Box id="compareUrlsBox" display="flex">
          <Autocomplete
            disablePortal
            options={urlList}
            onChange={(event, value) => handleAutoCompleteChanges(event, value)}
            renderInput={(params) => (
              <TextField
                // eslint-disable-next-line react/jsx-props-no-spreading,
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                {...(params as any)}
                label={t("commonLabels.firstWebsite")}
              />
            )}
          />
          <Autocomplete
            disablePortal
            options={urlList}
            onChange={(event, value) =>
              handleAutoCompleteChanges(event, value, false)
            }
            renderInput={(params) => (
              <TextField
                // eslint-disable-next-line react/jsx-props-no-spreading
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                {...(params as any)}
                label={t("commonLabels.secondWebsite")}
              />
            )}
          />
        </Box>
        <Box id="compareDateBox" display="flex" justifyContent="flex-end">
          <DatePicker
            label={t("commonLabels.startDate")}
            value={firstDate}
            onChange={(date) => {
              handleDateChange(date);
            }}
            disableFuture
            sx={{ width: "160px" }}
          />
          <Box
            display="flex"
            id="paddingBox"
            flexDirection="column"
            justifyContent="center"
            paddingX="20px"
          >
            -
          </Box>
          <DatePicker
            label={t("commonLabels.endDate")}
            minDate={firstDate}
            value={secondDate}
            onChange={(date) => {
              handleDateChange(date, false);
            }}
            disableFuture
            sx={{ width: "160px" }}
          />
        </Box>
      </LocalizationProvider>
    </Box>
  );
};

export default DateSelector;
